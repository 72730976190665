/* .Content {
    color: white;
    position: absolute;
    top: 36%;
    left: 25%;
}

.ContactBtn {
    border-radius: 34px;
    width: 9rem;
    height: 3rem;
}

.SecondSection {
    display: flex;
    justify-content: space-between;
}

.Services {
    background: #171A18;
    margin-top: 4vh;
    height: fit-content;
}

.Heading {
    color: white;
    display: flex;
    justify-content: space-between;
}

.TeamContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 7vh;
    align-items: center;
}

.WhyUs {
    background: #171A18;
    margin-top: 4vh;
    height: fit-content;
}

.WhyUsHeading {
    color: white;
    display: flex;
    justify-content: center;
}

.WhyUsContent {
    margin-bottom: 1rem;
} */


/*  */

.PageTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 59px;
    color: #171A18;
    width: 75%;
    margin-top: 25px;
}

.PageSubTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
    opacity: 0.7;
    margin-top: 16px;
}

.ContactBtn {
    border-radius: 34px;
    width: 9rem;
    height: 3rem;
    margin-top: 20px;
}

.PageSubtitleImageWrapper {
    margin: 30px 0 25px 0;
}

.PageSubtitleImageConatiner {
    height: 50px;
}

.PageSubtitleImage {
    height: 100%;
}

.TopImageContainer {
    width: 100%;
    height: 450px;
    border-radius: 42px;
    overflow: hidden;
    position: relative;
    background-image: url("../../assest/Idea.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.InnovateCont {
    background-image: url("../../assest/innovate.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.SmartCont {
    background-image: url("../../assest/smart.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.SecureCont {
    background-image: url("../../assest/secure.jpg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
}

.TopImageText {
    position: absolute;
    bottom: 40px;
    left: 40px;
}

.TopImageTextTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    width: 40%;
    color: #FFFFFF;
}

.TopImageTextSubTitle {
    font-family: 'Syne';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    width: 50%;
    margin-top: 20px;
    color: #FFFFFF;
}

.TopTwoimageContainer {
    width: 100%;
    height: 250px;
    border-radius: 32px;
    overflow: hidden;
    position: relative;
    color: #FFFFFF;
}

.TopTwoimageText {
    position: absolute;
    bottom: 30px;
    left: 30px;
}

.TopTwoImageTextTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 40px;
    width: 50%;
}

.ServicesSection {
    background-color: #171A18;
    padding: 50px 0 70px 0;
}

.ServiceTitle {
    font-family: 'Syne';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    color: #FFFFFF;
    width: 30%;
}

.ServiceSubTitle {
    font-family: 'Syne';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #FFFFFF;
    opacity: 0.7;
}

.CaruImageConatainer {
    position: relative;
    color: #FFFFFF;
    border-radius: 32px;
    overflow: hidden;
    height: 380px;
    width: 290px;
    margin: 0 15px;
}

.CaruImageTitle {
    position: absolute;
    left: 32px;
    bottom: 32px;
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    line-height: 44px;
    text-align: start;
    width: 50%;
}

.KnowImageContainer {
    width: 100%;
    height: 600px;
    border-radius: 40px;
    overflow: hidden;
    background-image: url("../../assest/team.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
}

.KnowTitle {
    font-family: 'Syne';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    color: #171A18;
    width: 30%;
}

.KnowSubTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-top: 20px;
    width: 70%;
    color: #000000;
    opacity: 0.7;
    margin-bottom: 20px;
}

.WhySection {
    background-color: #171A18;
    padding: 50px 0 70px 0;
    color: #FFFFFF;
}

.WhyTitle {
    text-align: center;
    font-family: 'Syne';
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 50px;
    color: #FFFFFF;
    margin-bottom: 90px;
}

.WhyImageContainer {
    width: 70%;
    height: 600px;
    border-radius: 40px;
    overflow: hidden;
    background-image: url("../../assest/whyus.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;
}

.WhyPointTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #FFFFFF;
}

.WhySubTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-top: 5px;
    width: 70%;
    color: #FFFFFF;
    opacity: 0.7;
    margin-bottom: 20px;
}

.FooterSection{
    background-color: #171A18;
}



@media only screen and (min-width: 320px) and (max-width: 767px) {
    .PageTitle {
        font-family: 'Outfit';
        font-size: 35px;
        line-height: 48px;
        width: 60%;
        margin-top: 0;
    }

    .PageSubTitle {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        margin-top: 12px;
    }

    .PageSubtitleImageConatiner {
        height: 45px;
    }

    .TopImageText {
        bottom: 25px;
        left: 25px;
    }

    .TopImageTextTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
    }

    .TopImageTextSubTitle {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
    }

    .TopTwoimageText {
        bottom: 25px;
        left: 25px;
    }

    .TopTwoImageTextTitle {
        font-family: 'Outfit';
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 35px;
    }

    .ServiceTitle {
        width: 40%;
        font-size: 35px;
        line-height: 48px;
    }

    .ServiceSubTitle {
        font-family: 'Syne';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
    }

    .CaruImageTitle {
        font-size: 30px;
        line-height: 35px;
        width: 50%;
    }

    .KnowTitle {
        font-size: 35px;
        line-height: 48px;
        width: 60%;
    }

    .KnowSubTitle {
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        margin-bottom: 20px;
    }

    .KnowImageContainer {
        height: 250px;
        border-radius: 30px;
    }

    .WhyTitle {
        margin-bottom: 50px;
        font-size: 35px;
        line-height: 48px;
    }

    .WhyImageContainer {
        min-width: 100%;
        height: 250px;
        border-radius: 30px;
    }

    .WhySubTitle {
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        margin-bottom: 20px;
    }

    .WhyPointTitle {
        font-size: 18px;
        line-height: 28px;
    }
}

/* @media only screen and (min-width: 480px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 991px) {

} */

@media only screen and (min-width: 992px) {
    .TopImageContainer {
        height: 100%;
    }

    .PageTitle {
        font-size: 45px;
        line-height: 59px;
        width: 73%;
    }
}
