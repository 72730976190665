.MainContainer {
    background: #171A18;
    border-radius: 2.08vw;
    margin: 2vw;
    padding: 2vw;
}

.AboutBtn {
    background: #FFFFFF;
    border-radius: 34px;
    color: black;
    height: 10.80vh;
    width: 17.3vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContentHeading {
    position: absolute;
    top: 5vh;
    left: 10vw;
    color: white;
}

.Content {
    position: absolute;
    top: 13vh;
    left: 2vw;
    color: white;
}

/* ul>li {
    margin-bottom: 5vh;
} */