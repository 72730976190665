.Link {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #171A18;
    text-decoration: none;
    margin: 10px;
}

.WhiteBg{
    background-color: #FFFFFF;
}