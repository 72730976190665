.Title {
    font-family: 'Syne';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 48px;
    color: #FFFFFF;
}

.TSubTitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #C9C9C9;
}

.SubWrapper {
    position: absolute;
    left: 32px;
    bottom: 32px;
}

.Subtitle {
    font-family: 'Outfit';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 36px;
    margin: 0;
}

.LeftDiv {
    background: black;
    height: 600px;
    border-radius: 8px;
    padding: 32px;
    position: relative;
    color: #FFFFFF;
}

.Blob1 {
    position: absolute;
    bottom: 10px;
    right: 25px;
    z-index: 2;
    height: 80px;
}

.Blob2 {
    position: absolute;
    bottom: -50px;
    right: -30px;
    height: 120px;
}

.BlobImage {
    height: 100%;
}

.Btn {
    background-color: black;
    color: white;
    border-radius: 5px;
    height: 3rem;
    width: 11rem;
    border: none;
}

input,
textarea {
    border: none !important;
    border-bottom: 1px solid gray !important;
    border-radius: 0 !important;
}

input,
textarea:focus {
    box-shadow: none !important;
}

.Icon {
    margin-right: 0.5rem;
}

.Error {
    color: red;
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
    .LeftDiv {
        height: 350px;
        padding: 25px;
    }

    .Title {
        font-size: 30px;
        line-height: 35px;
    }

    .TSubTitle {
        font-size: 16px;
        line-height: 24px;
    }

    .SubWrapper {
        left: 25px;
        bottom: 25px;
    }
}